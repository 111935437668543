import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"
import blogStyles from "./blog.module.scss"
const IndexPage = () => {
  return (
    <Layout>
      <Head title="Home" />
      <h2 className={blogStyles.newTitle}>Hello.I am Ali Pirani</h2>
      <h2 className={[blogStyles.newTitle, blogStyles.h2Summery].join(" ")}>
        I am a JavaScript developer working for Borna, in Tehran, Iran. I have
        delved into numerous programming languages. My interest in designing and
        developing engaging user interfaces is what drove me to specialise in
        Frontend Development.
      </h2>
    </Layout>
  )
}

export default IndexPage
